import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CPagingList,
  CNewsList,
  LPlan,
  CCategoryNav,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: {
    limit: number;
    skip: number;
    totalCount: number;
    current: number;
  };
}) => {
  const frontmatter = {
    title: 'お知らせ',
    description:
      '仙台ロイヤルパークホテル公式サイト。「お知らせ」のご案内。仙台駅から車で約30分とは思えない心が落ち着く自然溢れる美しい景色と、ヨーロッパの趣を感じる、優雅で快適な空間。非日常のOMOTENASHIを思う存分おたのしみください。',
  };

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'information',
            ja: 'お知らせ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/pickup/kv.png',
              },
              imgSp: {
                src: '/assets/images/pickup/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CCategoryNav
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                  blank: false,
                },
                current: true,
              },
              {
                label: 'ニュースリリース',
                link: {
                  href: '/release/',
                  blank: false,
                },
                current: false,
              },
            ]}
          />
          <CNewsList
            data={infoChoice(
              infoGet(),
              '',
              pageContext.limit + pageContext.skip,
              pageContext.skip
            )}
          />
          <CPagingList
            totalCount={pageContext.totalCount}
            path="/information/"
            perPage={pageContext.limit}
            current={pageContext.current}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;
